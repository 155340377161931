<template>
	<b-row>
		<b-col>
			<b-alert
				show
				variant="warning">
				<b-row>
					<b-col class="text-center">
						<h3>{{ translate('membership_expired_title') }}</h3>
						<p>{{ translate('membership_expired_text') }}</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col class="text-center">
						<button
							class="btn btn-primary"
							@click="$router.push({ name: 'Renewal' })">
							{{ translate('renew_membership') }}
						</button>
					</b-col>
				</b-row>
			</b-alert>
		</b-col>
	</b-row>
</template>

<script>

import { MembershipRenewal } from '@/translations';
import { distributor } from '@/settings/Roles';
import { RENEW_MEMBERSHIP_DAYS } from '@/settings/Dates';
import { FINAL_STATUSES_CAN_LOGIN } from '@/settings/Statuses';

export default {
	name: 'MembershipRenewal',
	messages: [MembershipRenewal],
	data() {
		return {
			distributor,
			renewMembershipDays: RENEW_MEMBERSHIP_DAYS,
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const isDistributor = vm.$user.details().type === vm.distributor;
			if (typeof FINAL_STATUSES_CAN_LOGIN[vm.$user.details().status] !== 'undefined') {
				vm.$router.replace({ name: 'AccountCanceled' });
			} else if (vm.$user.details().remaining_days_terminated > vm.renewMembershipDays && isDistributor) {
				vm.$router.replace({ name: 'MainHome' });
			}
		});
	},
};
</script>
